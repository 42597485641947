export default function oEvento(){


    // FAQ 
    const faqContent = document.querySelector('.content-faq')

    if(faqContent){
        faqContent.addEventListener('click', (e) => {
            const faqItems =    document.querySelectorAll('.item-faq')
            const faqItem = e.target.closest('.item-faq')

            if(!faqItem) return
                faqItems.forEach(i => i.classList.remove('active'))
                faqItem.classList.add('active')
            
        })
    } 



    
} 