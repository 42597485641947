export default function galeriaApi(inHome = false){
    const imageWrapper = document.querySelector('.image-galeria-wrapper');
    const sidebar = document.querySelectorAll('.sidebar li');


const renderImage = (data) => {
    if (!data){
        return
    } 
    const img =  data.map(i =>{
        return `
            <a href="${i.url}" data-fancybox="youtube">
                <img src="${i.url}" alt="item-galeria">
            </a>
        `
    }).join('')
   imageWrapper.innerHTML = img
}

function getProgramacao(){

// if(!data) return

const  url = `${window.location.origin}/home/api/galeria-semfiltro-json`
    axios.get(url)
        .then((response) => {
            if(response.data){
                console.log('Data:',response.data) 
                renderImage(response.data)
                
            }
        })
        .catch((response) => {
            console.log(response)  
        });
}

window.addEventListener('DOMContentLoaded',()=>{
    // console.log(window.history.pushState({}, '',`${window.location.origin}/home/api/galeria-semfiltro-json`)); 
    // window.location.replace(location.href)
    getProgramacao()
})

}


/*
CODE VERSÃO 1

const selectItemMenu = () => {
    sidebar.forEach(item =>{ 
        item.addEventListener('click', ()=>{
            sidebar.forEach(i =>{
                i.classList.remove('active');
            })
            item.classList.toggle('active');
            redirectUrl(item)             
        })
    })
}

const redirectUrl = (item) => {
    const diaEvento = item.dataset.diaEvento
    const novaURL = `${window.location.origin}${window.location.pathname}?data=${diaEvento}`;
    window.history.pushState({}, '', novaURL); 
    window.location.replace(location.href)
}


const reloadPage = () => {
    sidebar.forEach(item =>{
    if (!window.location.href.includes('?data=')){
            const diaEvento = item.dataset.diaEvento
            const novaURL = `${window.location.origin}${window.location.pathname}?data=${diaEvento}`;
            console.log('data',item.dataset, item);
            window.history.pushState({}, '', novaURL); 
            window.location.replace(location.href)
            activateItem(window.location.href, item)    
        }   
        activateItem(window.location.href, item)    
    })
    selectItemMenu()
}

const activateItem = (url, item) => {
    if (url.includes(`?data=${item.dataset.diaEvento}`))
        item.classList.toggle('active');
}

reloadPage()

*/

/*

const selectItemMenu = () => {
    sidebar.forEach(item =>{ 
        item.addEventListener('click', ()=>{
            sidebar.forEach(i =>{
                i.classList.remove('active');
            })
            item.classList.toggle('active');
            const diaEvento = item.dataset.diaEvento
            const novaURL = `${window.location.origin}${window.location.pathname}?data=${diaEvento}`;
            console.log('data',item.dataset, item);
            window.history.pushState({}, '', novaURL); 
            getProgramacao(diaEvento)
        })
    })
}

const renderImage = (data) => {
    const img = data.map(i =>{
        return `
            <a href="${i.url}" data-fancybox="youtube">
                <img src="${i.url}" alt="item-galeria">
            </a>
        `
    }).join('')
   imageWrapper.innerHTML = img
}

function getProgramacao(data){

if(!data) return

const  url = `/home/api/galeria-json?data=${data}`
    axios.get(url)
        .then((response) => {
            if(response.data){
                renderImage(response.data)
            }
        })
        .catch((response) => {
            console.log(response)  
        });
}

window.addEventListener('DOMContentLoaded',()=>{

    sidebar.forEach(item =>{
        if (!window.location.href.includes(`?data=`)){
            if(item.classList.contains('active'))
                window.history.pushState({}, '',`${window.location.origin}${window.location.pathname}?data=${item.dataset.diaEvento}`); 
                getProgramacao(item.dataset.diaEvento)
        }

        else if (window.location.href.includes(`?data=${item.dataset.diaEvento}`)){
            sidebar.forEach(i =>{
                i.classList.remove('active');
            })
                item.classList.toggle('active');
                getProgramacao(item.dataset.diaEvento)
        }
    }) 
        selectItemMenu()
})
*/