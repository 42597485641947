export default function animationHome(){


function floatingGirl(){
    const animation = gsap.to('.floating-girl img', {
      y: 10,
      duration: 2,
      yoyo: true,
      repeat: -1,
      ease: 'none',
    });
}

    function gatinhoEnave(){
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#atracoes",
                start: "top-=150px 90%", 
                end: "120px 70%", 
                
                scrub: 1
              },
            defaults:{ opacity: 0, ease: "none"}});

          tl
          .from('#o-evento .passaro-direita  ',  { opacity: 1,  x: 130, duration:1.5})


          gsap.from('#o-evento .passaro ', {

            scrollTrigger:{
                trigger: "#o-evento .flor",
                start: "top-=450px 100%",
                end: "50px 85%", 
                
               
                scrub:2
            },
            x: 150,
            opacity:1
          })
          gsap.from('#atracoes .passaro-direita ', {

            scrollTrigger:{
                trigger: ".minina-esquerda",
                start: "top-=150px 80%",
                end: "120px 45%", 
                
               
                scrub:2
            },
            x: 150,
            opacity:1
          })

          gsap.from('#o-evento .flor ', {

            scrollTrigger:{
                trigger: "#numeros",
                start: "top-=150px 90%",
                end: "70px 80%", 
                
               
                scrub:2
            },
            y: 250,
            opacity:1
          })

          gsap.from('#banner-patrocinio .robo-wrapper   ', {

            scrollTrigger:{
                trigger: ".container-banner-patrocinio",
                start: "top-=150px 70%",
                end: "120px 50%", 
                
               
                scrub:2
            },
            y: 200,
            opacity:1
          })
          
          
          

    }

    function bannerPatrocinio(){
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#banner-patrocinio",
                start: "top+=50px 80%",
              
                // scrub:1
              },
            defaults:{ opacity: 1, ease: "none"}});

            tl.from('#banner-patrocinio .robo-wrapper',{
               y: 200,              
            })
    }

    function contato(){
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#contato-footer",
                start: "top+=50px 80%",
              
                scrub:2
              },
            defaults:{ opacity: 1, ease: "none"}});
            // gsap.set('#contato-footer .contato-girl-orna',{ y: -100})

            tl.from('#contato-footer .contato-girl-orna', {
                y: -100
            })
    }

    gsap.to(".balanco", {
      duration: 8, // Duração da animação em segundos
      rotation: 34, // Define a rotação desejada em graus
      x: "-=100px",
      y: "+=60px", // Move o elemento -50 pixels no eixo X
       // Move o elemento -50 pixels no eixo X
      ease: "linear", // Especifica a função de easing linear para uma animação uniforme
      repeat: -1, // Repete a animação indefinidamente (-1 indica um loop infinito)
      yoyo: true // Faz com que a animação retorne ao estado inicial após cada iteração
    });



    gatinhoEnave();
    bannerPatrocinio();
    contato();
    floatingGirl();
}